import { render, staticRenderFns } from "./VpsStats.vue?vue&type=template&id=834c0b14&scoped=true"
import script from "./VpsStats.vue?vue&type=script&lang=js"
export * from "./VpsStats.vue?vue&type=script&lang=js"
import style0 from "./VpsStats.vue?vue&type=style&index=0&id=834c0b14&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "834c0b14",
  null
  
)

/* custom blocks */
import block0 from "./VpsStats.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports