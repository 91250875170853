<template>
  <div class="vps-stats">
    <base-datepicker-filter
      v-if="serverId"
      :periods="periods"
      :period="period"
      :value="dateFilter"
      class="vps-stats__filter"
      @init="onFilterInit"
      @change="onFilterChange"
    />
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <div v-else class="vps-stats__content">
        <div class="vps-stats__grid l-flex-1_md-2">
          <div v-for="(item, key) in datasets" :key="key" class="l-col">
            <vps-stats-item
              :title="$t(`titles.${key}`)"
              :labels="labels"
              :datasets="item"
              :units="units[key]"
              :type="types[key]"
              class="vps-stats__item"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import VpsStatsItem from './VpsStatsItem';
import BaseDatepickerFilter from '@/components/BaseDatepicker/BaseDatepickerFilter';
import { differenceInWeeks, getTime, isSameDay } from 'date-fns';
export default {
  name: 'VpsStats',
  components: { VpsStatsItem, BaseDatepickerFilter },
  props: {
    tariff: {
      type: VpsTariff,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      dataRaw: [],
      dateFilter: { start: new Date(), end: new Date() },
      periods: ['week', 'month', 'quarter', 'year'],
      period: 'week',
      units: {
        cpu: '%',
        filesystem: 'B',
        hdd: 'Bps',
        ram: 'B',
        netBps: 'Bps',
        netPps: 'pps',
      },
      types: {
        cpu: '%',
        filesystem: 'bytes',
        hdd: 'bytes',
        ram: 'bytes',
        netBps: 'bytes',
      },
    };
  },
  computed: {
    serverId() {
      return this.tariff.serverId;
    },
    datasets() {
      return this.dataRaw.reduce((acc, item) => {
        const { Cpu, FileSystem, HDD, Net, Ram } = item;
        // Cpu
        if (!acc.cpu) acc.cpu = [{ label: this.$t('labels.usage'), data: [] }];
        acc.cpu[0].data.push(Cpu && Cpu.Usage ? Cpu.Usage : 0);
        // FileSystem
        if (!acc.filesystem) {
          acc.filesystem = [
            { label: this.$t('labels.total'), data: [] },
            { label: this.$t('labels.usage'), data: [] },
          ];
        }
        acc.filesystem[0].data.push(FileSystem && FileSystem[0].Total ? FileSystem[0].Total : 0);
        acc.filesystem[1].data.push(
          FileSystem && FileSystem[0].Total ? FileSystem[0].Total - FileSystem[0].Free || 0 : 0
        );
        // HDD
        if (!acc.hdd) {
          acc.hdd = [
            { label: this.$t('labels.read'), data: [] },
            { label: this.$t('labels.write'), data: [] },
          ];
        }
        acc.hdd[0].data.push(HDD && HDD[0].Delta && HDD[0].Delta.Read ? HDD[0].Delta.Read : 0);
        acc.hdd[1].data.push(HDD && HDD[0].Delta && HDD[0].Delta.Write ? HDD[0].Delta.Write : 0);
        // Ram
        if (!acc.ram) {
          acc.ram = [
            { label: this.$t('labels.total'), data: [] },
            { label: this.$t('labels.usage'), data: [] },
            { label: this.$t('labels.cached'), data: [] },
          ];
        }
        acc.ram[0].data.push(Ram && Ram.Total ? Ram.Total * 1024 * 1024 : 0);
        acc.ram[1].data.push(Ram && Ram.Usage ? Ram.Usage * 1024 * 1024 : 0);
        acc.ram[2].data.push(Ram && Ram.Cached ? Ram.Cached * 1024 * 1024 : 0);
        // Net bps
        if (!acc.netBps) {
          acc.netBps = [
            { label: this.$t('labels.netIn'), data: [] },
            { label: this.$t('labels.netOut'), data: [] },
          ];
        }
        acc.netBps[0].data.push(
          Net && Net[0].Delta && Net[0].Delta.BytesIn ? Net[0].Delta.BytesIn * 8 : 0
        );
        acc.netBps[1].data.push(
          Net && Net[0].Delta && Net[0].Delta.BytesOut ? Net[0].Delta.BytesOut * 8 : 0
        );
        // Net pps
        if (!acc.netPps) {
          acc.netPps = [
            { label: this.$t('labels.netIn'), data: [] },
            { label: this.$t('labels.netOut'), data: [] },
          ];
        }
        acc.netPps[0].data.push(
          Net && Net[0].Delta && Net[0].Delta.PktsIn ? Net[0].Delta.PktsIn : 0
        );
        acc.netPps[1].data.push(
          Net && Net[0].Delta && Net[0].Delta.PktsOut ? Net[0].Delta.PktsOut : 0
        );
        return acc;
      }, {});
    },
    labels() {
      return this.dataRaw.reduce((acc, item) => {
        acc.push(item.Timestamp / 1000000);
        return acc;
      }, []);
    },
    retention() {
      let diff = differenceInWeeks(this.dateFilter.end, this.dateFilter.start);
      //if (diff <= 1) return '1w';
      /*else*/ if (diff <= 24) return '24w';
      else return 'inf';
    },
  },
  methods: {
    init() {
      this.isLoading = true;
      this.fetchStats().finally(() => (this.isLoading = false));
    },
    onFilterInit(value) {
      this.dateFilter = value;
      this.init();
    },
    onFilterChange(value) {
      this.dateFilter = value;
      this.fetchStats();
    },
    fetchStats() {
      const params = {
        from: getTime(this.dateFilter.start) * 1000000,
        to: getTime(this.dateFilter.end) * 1000000,
        retention: this.retention,
      };
      return this.$store
        .dispatch('moduleVPS/fetchServerInfo', {
          serverId: this.serverId,
          action: 'statistics',
          params,
        })
        .then(data => {
          if (data && data.length) this.dataRaw = data;
        });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "titles": {
      "cpu": "ЦПУ",
      "filesystem": "Файловая система",
      "hdd": "Диск",
      "netBps": "Трафик (бит/сек)",
      "netPps": "Трафик (пакет/сек)",
      "ram": "Память"
    },
    "labels": {
      "total": "Всего",
      "usage": "Использовано",
      "cached": "Кэш",
      "read": "Чтение байт/сек",
      "write": "Запись байт/сек",
      "netIn": "Входящий",
      "netOut": "Исходящий"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-stats {
  &__filter {
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
